var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pivot-table"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.loading
    }
  }), _vm.showPivot ? _c('Pivot', {
    ref: "pivot",
    attrs: {
      "toolbar": "",
      "beforetoolbarcreated": _vm.customizeToolbar,
      "report": _vm.pivotData
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }