













import Vue from 'vue'
import Component from 'vue-class-component'
import SygniLoader from '@/components/layout/SygniLoader.vue';
// import fakeData1 from "@/modules/accounting/containers/fakeData1";
import _ from 'lodash';

type SingeDataType = {
  additionDate: string | null,
  costCenter: string | null,
  counterPartyName: string | null,
  created: string | null,
  currency: string | null,
  documentNumber: string | null,
  documentReceiptDate: string | null,
  documentType: string | null,
  fileName: string | null,
  grossAmount: number | null,
  id: string | null,
  issueDate: string | null,
  legalEntityId: string | null,
  paymentDate: string | null,
  paymentStatus: string | null,
  saleDate: string | null,
  status: string | null,
  taxNumber: string | null,
}

@Component({
  components: {SygniLoader},
})

export default class AccountingPivotTable extends Vue {
  loading: boolean = true;
  data: any = [];
  pivotData: any = {};

  get showPivot() {
    return !_.isEmpty(this.pivotData) && this.pivotData.dataSource.data.length > 0;
  }

  setTableData(item: {
    title: string,
    id: number,
    data: SingeDataType[]
  }) {
    this.pivotData = item.data.length ? this.setTableObject(item.data) : this.setTableObject([]);
    this.loading = false;
  }

  setTableObject(data: SingeDataType[]) {
    return {
      options: {
        grid: {
          type: 'flat'
        }
      },
      dataSource: {
        data,
        mapping: {
          'reportNumber': {
            caption: 'Report Number',
            type: 'string',
          },
          'accountNumber': {
            caption: 'Account Number',
            type: 'string'
          },
          'number': {
            caption: 'Number',
            type: 'number'
          },
          'transactionNumber': {
            caption: 'Transaction Number',
            type: 'string'
          },
          'transactionDate': {
            caption: 'Transaction Date',
            type: 'datetime',
            format: 'yyyy-MM-dd'
          },
          'counterpartyName': {
            caption: 'Counterparty Name',
            type: 'string'
          },
          'counterpartyType': {
            caption: 'Counterparty Type',
            type: 'string'
          },
          'counterpartyAddress': {
            caption: 'Counterparty Address',
            type: 'string'
          },
          'counterpartyAccountNumber': {
            caption: 'Counterparty Account Number',
            type: 'string'
          },
          'ibanFormat': {
            caption: 'IBAN Format',
            type: 'string'
          },
          'description': {
            caption: 'Description',
            type: 'string'
          },
          'inflowPLN': {
            caption: 'Inflow PLN',
            type: 'number'
          },
          'outflowPLN': {
            caption: 'Outflow PLN',
            type: 'number'
          },
          'inflowCcy': {
            caption: 'Inflow Currency',
            type: 'number'
          },
          'outflowCcy': {
            caption: 'Outflow Currency',
            type: 'number'
          },
          'transactionCcy': {
            caption: 'Transaction Currency',
            type: 'string'
          },
          'transactionSettlementStatus': {
            caption: 'Transaction Settlement Status',
            type: 'string'
          },
        }
      },
      slice: {
        columns: [
          {
            uniqueName: 'reportNumber'
          },
          {
            uniqueName: 'accountNumber',
          },
          {
            uniqueName: 'number',
          },
          {
            uniqueName: 'transactionNumber',
          },
          {
            uniqueName: 'transactionDate',
          },
          {
            uniqueName: 'counterpartyName',
          },
          {
            uniqueName: 'counterpartyType',
          },
          {
            uniqueName: 'counterpartyAddress',
          },
          {
            uniqueName: 'counterpartyAccountNumber',
          },
          {
            uniqueName: 'ibanFormat',
          },
          {
            uniqueName: 'description',
          },
          {
            uniqueName: 'inflowPLN',
          },
          {
            uniqueName: 'outflowPLN',
          },
          {
            uniqueName: 'inflowCcy',
          },
          {
            uniqueName: 'outflowCcy',
          },
          {
            uniqueName: 'transactionCcy',
          },
          {
            uniqueName: 'transactionSettlementStatus',
          },
        ],
      },
      formats: [
        {
          decimalPlaces: 2,
          textAlign: 'left'
        }
      ],
    }
  }

  async fetchData() {
    // api call @HERE
    // this.data = [fakeData1];

    this.setTableData(_.isEmpty(this.data) ? [] : this.data[0]);
  }

  customizeToolbar(toolbar: any) {
    toolbar.showShareReportTab = false;

    // const tabs = toolbar.getTabs();
    // toolbar.getTabs = function () {

    //   tabs.unshift({
    //     id: "data-handler",
    //     title: "Data types",
    //     icon: this.icons.open,
    //     // menu: setToolbarDataMenu(this),
    //   });
    //   return tabs;
    // }

    // const setToolbarDataMenu = (toolbar: any) => {
    //   let menu: any = [];

    //   if (!_.isEmpty(this.data)) {
    //     this.data.forEach((item: any, key: number) => {
    //       const {id, title} = item;

    //       menu.push({
    //         handler: () => changePivot(key),
    //         id,
    //         icon: toolbar.icons.fields,
    //         mobile: false,
    //         title,
    //       })
    //     })
    //   }

    //   return menu;
    // }

    // const changePivot = (index: number) => {
    //   this.loading = true;
    //   // @ts-ignore
    //   this.$refs.pivot.flexmonster.updateData({data: this.data[index].data});
    //   this.loading = false;
    // }
  }

  mounted() {
    this.fetchData();
  }
}
